.orders {
  .addNew {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 131px;
    height: 47px;
    color: white;
    background: #04b700;
    border-radius: 8px;
    border: none;
    font-family: Montserrat;
  }

  .delete-btn {
    border: none;
    background-color: transparent;
  }
}
