.profile {
  .content {
    width: 80%;
    margin: auto;

    .profile-pic {
      height: 80px;
      width: 80px;
    }
    .header-pic {
      height: 80px;
    }
  }
  .edit-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 17px;
    gap: 10px;

    position: relative;
    width: 324px;
    height: 50px;

    background: #2b63c7;
    border-radius: 8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;

    color: #ffffff;
    border: none;
    margin-bottom: 48px;
  }
}
