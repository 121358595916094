.loginpls {
  text-align: center;
  margin-top: 240px;
}

.opacity {
  opacity: 0.2;
}

@media only screen and (max-width: 900px) {
  .dashboard {
    flex-direction: column;
    .right-panel {
      margin-left: 0px !important;
    }
    .left-panel {
      width: auto !important;
      height: auto !important;
      padding: 24px 0px !important;
      position: inherit !important;
    }
    .stat-cards {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }
}

.dashboard {
  display: flex;
  width: 100%;
  padding-bottom: 100px;

  .soon {
    margin-top: 20px;
    text-align: center;
  }

  .panel {
    height: 100vh;
    width: 300px;
    background-color: #1858ae;
    position: fixed;
    top: 0px;
    padding-top: 116px;
  }
  .left-panel {
    width: 327px;
    padding: 0 32px;
    background: #ffffff;
    box-shadow: 6px 0px 45px 15px rgba(0, 0, 0, 0.15);
    height: 100vh;
    position: fixed;
    // position: inherit;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 160px;
    align-items: center;
    gap: 16px;
    z-index: 0;
  }

  .right-panel {
    margin-left: 327px;

    display: flex;
    flex-direction: column;
    width: 100%;
    .header {
      height: 160px;
      background: radial-gradient(
        64.81% 1271.25% at 83.78% 100%,
        #91d7ff 0%,
        #47b1de 100%
      );
      background: radial-gradient(
        64.81% 1271.25% at 83.78% 100%,
        #91d7ff 0%,
        #47b1de 100%
      );
      margin-bottom: 42px;
      //   width: 100%;
      padding-top: 40px;
      padding-left: 42px;
      .header-text {
        color: white;
        font-family: "Montserrat";
        font-weight: 600;
      }
    }
    .stat-cards {
      width: 90%;
      margin: auto;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 14px;
        gap: 10px;
        width: 260px;
        // height: 78px;
        background: #ffffff;
        box-shadow: 2px 4px 13px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        gap: 16px;

        .text {
          display: flex;
          flex-direction: column;
          p {
            margin: 0;
          }
          .total {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;

            color: #000000;
          }
          .amount {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;

            color: #767676;
          }
        }
      }
    }
  }
  .a {
    width: 263px !important;
    height: 48px;

    background: #fff;
    border-radius: 8px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 24px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: none;
    color: #000000;
  }
  a {
    text-decoration: none !important;
  }
  .a:hover {
    cursor: pointer;
    background: #ededed;
  }
  .a:focus {
    background: #ededed;
  }
}
